import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import skadi from "../img/social/skadi.svg";
import instagram from "../img/social/instagram.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/thema">
                        Thema
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/winkel">
                        Winkel
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/partners">
                        Partners
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/vragen">
                        Vragen
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to="/winkel/algemene-voorwaarden"
                      >
                        Algemene Voorwaarden
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 is-vcentered">
                <img
                  src={logo}
                  alt="Dorsten"
                  style={{ width: "14em", height: "10em" }}
                />
                <p className="theme-italic is-size-5">
                  DORSTEN. XIXe lustrum der A.R.S.R. "Skadi"
                </p>
              </div>
              <div className="column is-4 is-vcentered">
                <a
                  title="instagram"
                  rel="noreferrer"
                  target="_blank"
                  href="https://instagram.com/skadilustrum"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </a>
                <a
                  title="skadi"
                  rel="noreferrer"
                  target="_blank"
                  href="https://skadi.nl"
                >
                  <img
                    src={skadi}
                    alt="Skadi"
                    style={{
                      width: "3rem",
                      height: "3rem",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
